<template>
  <div class="all-login">
    <div class="container">
      <div class="wrapper">
        <img
          src="../assets/images/logo.png"
          alt="Siber"
          class="logo animated zoomIn"
          style="animation-delay: 300ms"
        />
        <div class="input-holder">
          <div v-show="!validEmail">
            <div
              class="input-item icon animated fadeInLeft"
              style="animation-delay: 650ms"
            >
              <span> Informe o seu e-mail * </span>
              <font-awesome-icon :icon="['fas', 'envelope']" />
              <input v-model="user.email" type="email" />
            </div>

            <transition enter-active-class="animated fadeIn">
              <p
                v-show="warningMessage != ''"
                class="warning mt-2"
                style="position: relative; display: block"
              >
                {{ warningMessage }}
              </p>
            </transition>

            <button
              @click="checkEmail()"
              class="btn mt-2 desktop4 mx-auto mobile8 animated flipInX"
              style="animation-delay: 1050ms"
            >
              <span v-show="!loading">Avançar</span>
              <div v-show="loading" class="loading2 white"></div>
            </button>
            <router-link
              to="/login"
              class="forgot-password mx-auto mt-1 animated fadeInRight"
              style="
                position: relative;
                display: block;
                text-align: center;
                animation-delay: 1450ms;
              "
            >
              Voltar
            </router-link>
          </div>

          <div v-show="validEmail && !validConfirmationCode">
            <p
              class="warning mt-2 animated flipInX"
              style="position: relative; display: block; animation-delay: 250ms"
            >
              Enviamos um código para o e-mail {{ userEmail }}. <br /><br />
              Por favor, insira o código recebido no campo abaixo.
            </p>

            <div
              class="input-item icon animated fadeInLeft"
              style="animation-delay: 650ms"
            >
              <span> Código * </span>
              <font-awesome-icon :icon="['fas', 'envelope']" />
              <input type="text" v-model="user.code" />
            </div>

            <transition enter-active-class="animated fadeIn">
              <p
                v-show="warningMessage != ''"
                class="warning mt-2"
                style="position: relative; display: block"
              >
                {{ warningMessage }}
              </p>
            </transition>

            <button
              class="btn mt-2 desktop4 mx-auto mobile8 animated flipInX"
              style="animation-delay: 1050ms"
              @click="checkConfirmationCode()"
            >
              <span v-show="!loading">AVANÇAR</span>
              <div v-show="loading" class="loading2 white"></div>
            </button>
            <router-link
              to="/login"
              class="forgot-password mx-auto mt-1 animated fadeInRight"
              style="
                position: relative;
                display: block;
                text-align: center;
                animation-delay: 1450ms;
              "
            >
              Voltar
            </router-link>
          </div>

          <div v-show="validConfirmationCode">
            <div
              class="input-item icon animated fadeInLeft"
              style="animation-delay: 650ms"
            >
              <span> Nova senha * </span>
              <font-awesome-icon :icon="['fa', 'key']" />
              <input
                type="password"
                v-model="user.newPassword"
                placeholder="Digite a sua nova senha"
              />
            </div>
            <div
              class="input-item icon animated fadeInLeft"
              style="animation-delay: 650ms"
            >
              <span> Confirmar senha * </span>
              <font-awesome-icon :icon="['fa', 'key']" />
              <input
                type="password"
                v-model="user.password2"
                placeholder="Confirme a sua senha"
              />
            </div>

            <transition enter-active-class="animated fadeIn">
              <p
                v-show="warningMessage != ''"
                class="warning mt-2"
                style="
                  position: relative;
                  display: block;
                  animation-delay: 500ms;
                "
              >
                {{ warningMessage }}
              </p>
            </transition>

            <transition enter-active-class="animated fadeIn">
              <p
                v-show="success"
                class="warning mt-2 animated flipInX"
                style="position: relative; display: block; color: #fff"
              >
                Senha alterada com sucesso.
                <br />
                <router-link
                  to="/login"
                  style="color: #003da7; font-size: 14px; font-weight: bold"
                >
                  Faça login
                </router-link>
                agora mesmo.
              </p>
            </transition>

            <button
              v-show="!success"
              class="btn mt-2 desktop4 mx-auto mobile8 animated flipInX"
              style="animation-delay: 1050ms"
              @click="changePassword()"
            >
              <span v-show="!loading">Trocar senha</span>
              <div v-show="loading" class="loading2 white"></div>
            </button>
            <router-link
              to="/login"
              class="forgot-password mx-auto mt-1 animated fadeInRight"
              style="
                position: relative;
                display: block;
                text-align: center;
                animation-delay: 1450ms;
              "
            >
              Voltar
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import http from "@/http";
import validators from "../util/validators";

export default {
  name: "PasswordRecovery",
  data() {
    return {
      validEmail: false,
      validConfirmationCode: false,
      user: {
        email: "",
        code: null,
        newPassword: "",
        password2: "",
      },
      loading: false,
      warningMessage: "",
      success: false,
      userEmail: "",
    };
  },
  created() {},
  methods: {
    checkEmail() {
      if (!validators.validateEmail(this.user.email)) {
        this.warningMessage = "Por favor, informe um e-mail válido.";
        return;
      }

      this.loading = true;
      this.warningMessage = "";

      http
        .post(`user/forgotPassword?email=${this.user.email}`)
        .then((response) => {
          this.userEmail = response.data;
          this.validEmail = true;
          this.warningMessage = "";
        })
        .catch((err) => {
          if (err.response.status === 404) {
            this.warningMessage = "Usuário não encontrado em nosso sistema.";
          } else {
            this.warningMessage = err.response.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkConfirmationCode() {
      this.loading = true;
      http
        .post("user/forgotPassword/validate", this.user)
        .then(() => {
          this.warningMessage = "";
          this.validConfirmationCode = true;
        })
        .catch((err) => {
          console.log(err);
          this.warningMessage =
            "Parece que o código informado está incorreto. Verifique e tente novamente.";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changePassword() {
      if (!this.user.newPassword) {
        this.warningMessage = "Por favor, informe sua nova senha";
        return;
      }
      if (!this.user.password2) {
        this.warningMessage = "Por favor, confirme a sua nova senha";
        return;
      }
      if (this.user.newPassword != this.user.password2) {
        this.warningMessage =
          "Sua senha e confirmação de senha são diferentes. Por favor, verifique e tente novamente";
        return;
      }

      this.warningMessage = "";
      this.loading = true;

      http
        .post("user/resetPassword", this.user)
        .then(() => {
          this.success = true;
        })
        .catch((err) => {
          this.warningMessage = err.response.data;
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.pwd-recovery {
  position: absolute;
  right: 0;
  left: 0;
}

header .container img.back {
  z-index: 9;
}

.text-desc a {
  font-size: 1em;
}

.text-desc.success {
  text-align: center;
}

.all-login {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  background-color: #ecb0bf;
  // background-image: url("../assets/images/login-bg.png");
  background-position: left center;
  background-size: 50%;
  background-blend-mode: overlay;
}
.logo {
  position: relative;
  display: block;
  max-width: 150px;
  margin: 20px auto 0 auto;
}
.wrapper {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  min-height: 70vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.input-holder {
  width: 100%;
  .input-item span {
    color: #fff;
  }
  .subtitle.registered-user {
    text-align: center;
    margin-bottom: 25px;
  }
}
.warning,
.forgot-password {
  position: relative;
  font-size: 1.15em;
  color: #fff;
  text-align: center;
}

.forgot-password {
  letter-spacing: 1px;
  color: #fff;
}
</style>
